<template>
	<div>
		<TableList :columns="columns" :get_table_list="this.get_table_list" :slot_table_list="['operation','status']"
		 :is_page="false" rowKey="id" ref="list" @list_after="list_after">
			<template slot="left_btn">
				<a-button class="float_left" v-auth="'change'" @click="add()" type="primary">添加类型</a-button>
			</template>
			<template slot="right_btn">
				<a-button class="float_right" v-auth="'change'" @click="add2()" type="primary">设置发放时间</a-button>
			</template>
			<template slot="status" slot-scope="data">
				<status :value="data.text"></status>
			</template>
			<template slot="operation" slot-scope="data">
				<a v-if="data.record.pid == 0" v-auth="'change'" @click="edit(data.record,'add')">添加</a>
				<a-divider v-if="data.record.pid == 0" v-auth="'change'" type="vertical" />
				<a v-auth="'change'" @click="edit(data.record)">编辑</a>
				<a-divider v-auth="'delete'" type="vertical" />
				<a-popconfirm title="您确定要删除吗?" v-auth="'delete'" @confirm="del(data.record)">
					<a>删除</a>
				</a-popconfirm>
			</template>
		</TableList>
		<!-- <EditPop:form_data="form_data" :visible.sync="visible"></EditPop> -->
		<a-modal title="设置发放时间" :visible="visible2" :width="'500px'"  @ok="submit" @cancel="visible2 = false" >
			
			<a-form-model-item  :autoLink="false" >
				<div class="launch">
					<span class="launch_label">提醒发放时间:</span>
					<a-radio-group @change="handleChange" 	:defaultValue="time_type">
						<!-- <a-radio value="0" >提前  <a-input v-model='week' />  周</a-radio>
						<a-radio value="1">提前 <a-input  v-model='month'/> 月</a-radio> -->
						<div>提前 <a-input v-model='day' /> 天</div> 
					</a-radio-group>
				</div>
			</a-form-model-item>
		</a-modal>
	</div>
</template>
<script>
	// @ is an alias to /src
	import EditPop from "@/components/EditPop";
	import Form from "@/components/Form";
	import TableList from "@/components/TableList";
	import {
		getInvestmentType,
		saveType,
		removeType,setTimes,getTimes
	} from '@/api/approval'
	const columns = [{
			title: "类型名称",
			dataIndex: "name",
		},
		{
			title: "状态",
			dataIndex: "status",
			scopedSlots: {
				customRender: "status"
			}
		},
		{
			title: "备注",
			dataIndex: "remark",
		},
		{
			title: "操作",
			scopedSlots: {
				customRender: "operation"
			}
		}
	];

	export default {
		name: "Index",
		components: {
			EditPop,
			Form,
			TableList
		},
		data() {
			return {
				week:'',
				day:'',
				month:'',
				time_type: 2,
				visible2: false,
				get_table_list: getInvestmentType,
				columns,
				visible: false,
				form_data_seo: {
					list: [{
							type: 'text',
							name: 'customer',
							title: '客户',
							placeholder: '客户名称/ID/地址'
						},
						{
							type: 'select',
							name: 'type',
							title: '投放类型',
							list: [

							]
						},
						{
							type: 'select',
							name: 'type2',
							title: '投放状态',
							list: [

							]
						}
					]
				},
				form_data: {
					title: "添加",
					list: [{
							type: 'text',
							name: 'id',
							hidden: true,
							options: {},
						},
						{
							type: "text",
							name: "pid",
							title: "上级类型",
							hidden: true,
							list: [],
							options: {
								initialValue: 0
							}
						},
						{
							type: 'text',
							title: "类型名称",
							name: 'name',
							options: {},
						},
						{
							type: 'textarea',
							title: "备注",
							name: 'remark',
							options: {},
						},
						{
							type: "radio",

							name: "status",
							title: "状态",
							options: {
								initialValue: 1
							},
							list: this.$config.status_list
						},
					]
				},

			};
		},
		created() {
			this.getTime()
		},
		methods: {
			getTime(){
				getTimes().then(res=>{
					this.day=res.data.list

					// if(res.data.type=='0') {
					// 	this.week=res.data.number
					// }
					
					// if(res.data.type=='1') {
					// 	this.month=res.data.number
					// }
					
					// if(res.data.type=='2') {
					// 	this.day=res.data.list
					// }
					// this.time_type=res.data.type
				})
			},
			handleChange(e){
			if(this.time_type != e.target.value) {
				this.week=''
				this.day=''
				this.month=''
			}
				this.time_type= e.target.value
			},
			list_after() {
				let list = this.$method.get_depart_list(this.$refs.list.list, 'children', 'id', 'title');
			},
			add2() {
				this.visible2 = true;
				// this.getTime()
			},
			add() {
				this.form_data.title = "添加"

				this.$refs.list.$refs.form_seo.form.resetFields();

				this.form_data.list.forEach(item => {
					if (item.name == 'pid') {
						item.options.initialValue = 0;
					} else {
						item.options.initialValue = '';
					}
				})
				this.visible = true;
			},
			del(data) {
				removeType({
					data: {
						id: data.id
					},
					info: true,
				}).then(res => {
					this.$refs.list.get_list();
				})
			},
			edit(data, type = 'edit') {

				if (type == 'add') {
					this.form_data.title = "添加"
					this.$refs.list.$refs.form_seo.form.resetFields();
					this.form_data.list.forEach(item => {
						if (item.name == 'pid') {
							item.options.initialValue = data['id'];
						} else {
							item.options.initialValue = "";
						}
					})
				} else {
					this.form_data.title = "编辑"
					this.form_data.list.forEach(item => {
						item.options.initialValue = data[item.name];
					})
				}

				this.visible = true;
			},
			submit(e) {

				
				// let obj = {
				// 	type:this.time_type,
				// 	number:''
				// }
				// if(this.time_type=='0' ){
				// 	obj.number=this.week
				// }
				// if(this.time_type=='1' ){
				// 	obj.number=this.month
				// }
				// if(this.time_type=='2' ){
				// 	obj.number=this.day
				// }
				// if(obj.number) {
					setTimes({data:{
						number : this.day
					}}).then(res=>{
						// this.week=''
						this.day=''
						// this.month=''
						// this.time_type=0
						this.visible2=false
						this.getTime()
					})
					// }
			
			}

		}
	};
</script>
<style lang="less">
	
	.launch {
		.ant-radio-group {
			padding-left: 10px;
			.ant-radio-wrapper {
				margin-top: 6px;
			}
			.ant-input {
				width: 150px;
			}
		}
	}
	.launch_label {
		width: 100px;
		display: inline-block;
		text-align: right;
	}
	.ant-select {
		margin-left: 18px;
		width: 200px;
	}
</style>
